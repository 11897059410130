export const mapLayers = {
  order: 3,
  name: "Map layers",
  keywords: "satellite transit",
  subcategory: "The map",
  markdown: `# Map layers

  Four layers are available on {% inlineRouterLink %}Big Map{% /inlineRouterLink %} and {% inlineRouterLink %}Compact Map{% /inlineRouterLink %} within a property page. Select a layer with the {% inlineAppIcon iconName="layers" %}**layer control**{% /inlineAppIcon %} in the upper-right corner of the map.

  ## Base

  A greyscale view intended to make {% inlineRouterLink %}markers{% /inlineRouterLink %} and {% inlineRouterLink articleId="explored-areas" %}explored areas{% /inlineRouterLink %} stand out. Base is the default layer when zoomed out.  
    
  Labels are limited to cities, states, countries, roads, and bodies of water. Building outlines appear at local zoom levels.

  ![Base layer screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% callout type="tip" %}
  **Tip:** Press {% inlineKeyboardShortcut %}B{% /inlineKeyboardShortcut %} to switch to the base layer.
  {% /callout %}

  ## Satellite

  A color photographic view intended to make it easy to identify specific properties based on their real-world appearance. No labels are shown.  
    
  Satellite layer is enabled automatically when zoom level transitions from regional to local.

  ![Satellite layer screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% callout type="tip" %}
  **Tip:** Press {% inlineKeyboardShortcut %}S{% /inlineKeyboardShortcut %} to switch to the satellite layer.
  {% /callout %}
  
  ## Hybrid

  A color photographic view intended to maximize nearby context. A wide variety of third-party points of interest are labeled alongside cities, states, countries, roads, and bodies of water.

  ![Hybrid layer screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% callout type="tip" %}
  **Tip:** Press {% inlineKeyboardShortcut %}H{% /inlineKeyboardShortcut %} to switch to the hybrid layer.
  {% /callout %}
  
  ## Transit

  A color view intended to emphasize nearby public transit nodes such as rail and bus. A limited variety of third-party points of interest are labeled alongside cities, states, countries, roads, and bodies of water.

  ![Transit layer screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% callout type="tip" %}
  **Tip:** Press {% inlineKeyboardShortcut %}T{% /inlineKeyboardShortcut %} to switch to the transit layer.
  {% /callout %}`,
};
